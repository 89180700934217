import React from 'react'
import useRemark from 'hooks/useRemark'
import Component from './index.js'

export default function EditableTransformer({ data, ...props }) {
  const descriptions = useRemark(data?.slides?.map(slide => slide.description))
  const text = useRemark(data?.text)
  return (
    <Component
      data={{
        ...data,
        text,
        slides: data?.slides?.map((slide, idx) => ({
          ...slide,
          description: descriptions[idx],
        })),
      }}
      {...props}
    />
  )
}
